@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy SemiBold Italic'), local('Gilroy-SemiBoldItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy ExtraBold Italic'), local('Gilroy-ExtraBoldItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot);
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.eot?#iefix) format('embedded-opentype'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.woff) format('font-woff'),
        url(/fonts/d41d8cd98f00b204e9800998ecf8427e.ttf) format('ttf');
    font-weight: 900;
    font-style: normal;
}
